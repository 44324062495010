import React from "react"
import { graphql } from "gatsby"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"
import { Marker, Phone, Envelope, Facebook } from "../components/Icons"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./kontakt.scss"

export default () => {
  const { t } = useTranslation()
  const openHours = [9, 17]
  return (
    <Layout className="page contact" openHours={openHours}>
      <SEO title={t("contact")} />
      <h1>
        <Trans t={t} i18nKey="contact" />
      </h1>
      <br />
      <div className="contact__content">
        <div className="contact__contacts">
          <a
            href="https://goo.gl/maps/5YcpP6gwrFQuSLdy9"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Marker /> <span>Vídeňská 5, Olomouc</span>
          </a>
          <a href="tel:00420721516598">
            <Phone /> <span>+420 721 516 598</span>
          </a>
          <a href="mailto:bajkazyl.ol@gmail.com">
            <Envelope /> <span>bajkazyl.ol@gmail.com</span>
          </a>
          <a
            href="https://www.facebook.com/BajkazylOlomouc/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Facebook />
            <span>Facebook</span>
          </a>
        </div>
        <div className="contact__openHours">
          <p>
            <span>
              <b>
                <Trans t={t} i18nKey="openhours.openHours" />
              </b>
              <span>
                <Trans t={t} i18nKey="openhours.monThu" />
              </span>
              <b>
                {openHours[0]}-{openHours[1]}
              </b>{" "}
              <Trans t={t} i18nKey="openhours.hours" />
              <br />
              <span>
                <Trans t={t} i18nKey="openhours.fri" />
              </span>
              <b>
                {openHours[0]}-{openHours[1] - 1}
              </b>{" "}
              <Trans t={t} i18nKey="openhours.hours" />
            </span>
          </p>
        </div>
      </div>
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2586.3675970057548!2d17.246453!3d49.590817!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47124e8c39980cf3%3A0x769eb4c591877453!2sBajkazyl%20Olomouc!5e0!3m2!1sen!2shr!4v1583442936472!5m2!1sen!2shr"
        width="600"
        height="450"
        frameBorder="0"
        allowFullScreen=""
      ></iframe>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
